<script setup lang="jsx">
import { useHttps } from "@/plugins/http";
import { useChatStore } from "@/stores/chat.store";
import { useUserStore } from "@/stores/user.store";
import { onMounted, reactive, computed } from "vue";
import ChatUserOrderItem from "./item/ChatUserOrderItem.vue";

const https = useHttps();
const chatStore = useChatStore();
const userStore = useUserStore();

const currentChat = computed(() => chatStore.getCurrentChat);
const storedUser = computed(() => ({
  id: userStore.getUserId,
}));
const receiver = computed(() => ({
  userId:
    currentChat.value.senderID === storedUser.value.id
      ? currentChat.value.receiverID
      : currentChat.value.senderID,
  username:
    currentChat.value.senderID === storedUser.value.id
      ? currentChat.value.receiverName
      : currentChat.value.senderName,
}));

const state = reactive({
  data: [],
  loading: false,
  type: "sell",
  page: 1,
  limit: 20,
  isNextPage: false,
});

const notFoundText = computed(() =>
  state.type === "sell"
    ? getSellNotFoundText(receiver.value.username)
    : getSoldNotFoundText(receiver.value.username)
);

const getSoldNotFoundText = (username) => {
  return `${username} adlı kullanıcıdan satın alınan herhangi bir ilan bulunamadı.`;
};

const getSellNotFoundText = (username) => {
  return `${username} adlı kullanıcıya satılan herhangi bir ilan bulunamadı.`;
};

const getOrders = async () => {
  state.loading = true;
  const path =
    state.type === "sell" ? "/userOrdersFromMe" : "/myOrdersFromUser";
  const response = await https.post(
    path,
    `UserId=${receiver.value.userId}&Page=${state.page}`
  );
  state.loading = false;
  if (response && response.data && response.data.success) {
    state.data = [...response.data.datas];
    state.limit = response.data.limit;
    setCache();
  }
};

const getSellOrders = () => {
  state.type = "sell";
  state.page = 1;
  checkCache({ page: 1 });
};

const getSoldOrders = () => {
  state.type = "sold";
  state.page = 1;
  checkCache({ page: 1 });
};

const getCacheKey = () => {
  return `cache:orders:u_${receiver.value.userId}:${state.type}`;
};

const setCache = () => {
  const cachedDate = {
    datas: state.data,
    page: state.page,
    time: Math.floor(Date.now() / 1000) + 60 * 5,
  };
  localStorage.setItem(getCacheKey(), JSON.stringify(cachedDate));
};

const checkCache = ({ page = 1 }) => {
  if (!receiver.value.userId)
    return setTimeout(() => checkCache({ page }), 130);
  const cache = JSON.parse(localStorage.getItem(getCacheKey()) || "{}");
  if (
    cache &&
    typeof cache === "object" &&
    !!cache.datas &&
    Math.floor(Date.now() / 1000) < cache.time &&
    cache.page === page
  ) {
    state.data = cache.datas;
    state.isNextPage = cache.datas % state.limit === 0;
    state.page = cache.page;
    return;
  }
  getOrders();
};

onMounted(() => {
  checkCache({ page: 1 });
});
</script>

<template>
  <is-spin :loading="state.loading">
    <div class="chat-user-order-view-head">
      <div class="order-state-selection-group">
        <div
          class="order-state-selection-item clickable order-primary"
          :class="{ 'text-decoration-line-through': state.type === 'sell' }"
          @click="getSoldOrders"
        >
          <span class="order-state-indicator"></span>
          <span class="order-state-text">Satın Aldıklarım</span>
        </div>
        <div
          class="order-state-selection-item clickable order-secondary"
          :class="{ 'text-decoration-line-through': state.type === 'sold' }"
          @click="getSellOrders"
        >
          <span class="order-state-indicator"></span>
          <span class="order-state-text">Sattıklarım</span>
        </div>
      </div>
    </div>
    <div class="chat-user-order-view-body">
      <template v-if="state.data.length === 0">
        <a-empty image="/img/static/search-engine.png" class="mt-3">
          <template #description>
            <p class="no-margin is-description">
              {{ notFoundText }}
            </p>
          </template>
        </a-empty>
      </template>
      <template v-else>
        <a-timeline mode="left">
          <a-timeline-item
            v-for="(order, index) in state.data"
            :key="index"
            :color="state.type === 'sold' ? 'primary' : 'secondary'"
          >
            <chat-user-order-item
              :id="order.Id"
              :username="receiver.username"
              :post-title="order.Title"
              :post-id="order.AdvertId"
              :post-image="order.AdvertImage"
              :post-category="order.CategoryName"
              :date="order.Datetime"
              :price="order.Price"
              :state="order.State"
              :state-text="order.StateText"
              :is-sold="state.type === 'sold'"
            ></chat-user-order-item>
          </a-timeline-item>
        </a-timeline>
      </template>
    </div>
  </is-spin>
</template>

<style lang="scss">
.chat-user-order-view-head {
  .order-state-selection-group {
    margin-bottom: 1.5rem;
    .order-state-selection-item {
      display: flex;
      align-items: center;
      text-decoration-color: var(--default-text-color) !important;
      .order-state-indicator {
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 50%;
        line-height: 0.75rem;
        margin-right: 0.5rem;
        border-radius: 0.25rem;
        display: flex;
      }
      &.order-primary {
        .order-state-indicator {
          background-color: var(--theme-primary-color);
        }
      }
      &.order-secondary {
        .order-state-indicator {
          background-color: var(--theme-primary-color-extra-light);
        }
      }
      .order-state-text {
        color: var(--default-text-color);
        font-size: 0.875rem;
      }
    }
  }
}
.chat-user-order-view-body {
  .ant-timeline-item-head-secondary {
    background-color: var(--theme-primary-color-extra-light);
  }
  .ant-timeline-item-head-primary {
    color: var(--theme-primary-color);
    background-color: var(--theme-primary-color);
  }
}
</style>
