<script setup lang="jsx">
import { useMoment } from "@/plugins/moment";
import { inject } from "vue";
import VueTypes from "vue-types";

const displayWidth = inject("displayWidth");

const moment = useMoment();

defineProps({
  id: VueTypes.oneOfType([VueTypes.string, VueTypes.number]).required,
  username: VueTypes.string.required,
  postTitle: VueTypes.string.required,
  date: VueTypes.string.required,
  price: VueTypes.oneOfType([VueTypes.string, VueTypes.number]).required,
  isSold: VueTypes.bool.def(false),
  state: VueTypes.string,
  stateText: VueTypes.string,
});

const getDateText = (date) => {
  const momentDate = moment(date);
  return momentDate.format("DD MMMM YYYY HH:mm");
};

const getColorByState = (state) => {
  if (+state === 0) return "gray";
  if (+state === 1) return "green";
  if ([2, 3, 4].includes(+state)) return "orange";
  if (+state === 5) return "cyan";
  if ([6, 7, 8].includes(+state)) return "red";
};

const SoldText = ({ username, postTitle, date, price }) => {
  return (
    <>
      <b class="order-username">{username}</b> adlı kullanıcıdan{" "}
      <b class="order-post-title">{postTitle}</b> adlı gönderiyi <b>{date}</b>{" "}
      tarihinde, <b class="order-price">{price}</b> ₺ karşılığında satın
      aldınız.
    </>
  );
};

const SellText = ({ username, postTitle, date, price }) => {
  return (
    <>
      <b class="order-username">{username}</b> adlı kullanıcı{" "}
      <b class="order-post-title">{postTitle}</b> adlı gönderiyi <b>{date}</b>{" "}
      tarihinde, <b class="order-price">{price} ₺</b> karşılığında satın aldı.
    </>
  );
};
</script>

<template>
  <a-tooltip
    :title="stateText"
    :color="getColorByState(state)"
    :placement="displayWidth > 992 ? 'left' : 'top'"
  >
    <div
      class="user-order-item"
      :class="{ 'order-primary': isSold, 'order-secondary': !isSold }"
    >
      <span class="order-id is-description">
        <span>#{{ id }}</span>
      </span>
      <p class="no-margin is-description order-text">
        <template v-if="isSold">
          <sold-text
            :price="price"
            :username="username"
            :postTitle="postTitle"
            :date="getDateText(date)"
          ></sold-text>
        </template>
        <template v-else>
          <sell-text
            :price="price"
            :username="username"
            :postTitle="postTitle"
            :date="getDateText(date)"
          ></sell-text>
        </template>
      </p>
    </div>
  </a-tooltip>
</template>

<style lang="scss">
.user-order-item {
  padding: 0.625rem 1rem;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
  flex-wrap: wrap;
  transition: all 0.3s;
  cursor: pointer;
  b {
    font-weight: bold !important;
  }
  &.order-primary {
    background-color: var(--theme-primary-color-100);
    box-shadow: var(--theme-primary-color-400) 0px 2px 4px;
    &:hover {
      box-shadow: var(--theme-primary-color-400) 0px 4px 14px;
    }
  }
  &.order-secondary {
    background-color: var(--theme-primary-color-extra-light-100);
    box-shadow: var(--theme-primary-color-extra-light-400) 0px 2px 4px;
    &:hover {
      box-shadow: var(--theme-primary-color-extra-light-400) 0px 4px 14px;
    }
  }
}
</style>
